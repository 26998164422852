.mySwiper {
    width: 38vw;
    height: 22vw;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .mySwiper {
        width: 90vw;
        height: 70vw;
    }
}
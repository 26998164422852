@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #333333;
  margin-bottom: 30px;
}

.custom-hover {
  transition: ease-in-out 330ms;
  cursor: pointer;
}

.custom-hover:hover {
  transform: scale(1.05);
  transition: ease-in-out 330ms
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-bg {
  background-color: red;
  border-radius: 50%;
}

.loader-circle {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  z-index: 10;
  background: #000;
  color: #000;
  align-self: center;
  margin-top: -50px;
  margin-left: 38px;
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 90px;
  aspect-ratio: 1;
  --c: no-repeat radial-gradient(farthest-side, black 92%, #0000);
  background:
    var(--c) 50% 0,
    var(--c) 50% 100%,
    var(--c) 100% 50%,
    var(--c) 0 50%;
  background-size: 15px 15px;
  animation: l18 1s infinite;
  position: relative;
}

.loader::before {
  content: "";
  position: absolute;
  inset: 0;
  margin: 3px;
  background: repeating-conic-gradient(#0000 0 35deg, #000 0 90deg);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  border-radius: 50%;
}

@keyframes l18 {
  100% {
    transform: rotate(.5turn)
  }
}